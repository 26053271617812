import axios from 'axios'

class salaryOtherDailyApi {
  async get({ start_date, end_date, page, limit, search }) {
    axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken')
    const response = await axios.get(
      `counting/otherSalary?search=${search}&start_date=${start_date}&end_date=${end_date}&page=${page}&limit=${limit}`,
    )
    return response
  }
  async getEditedLog({ id, page, limit, search }) {
    axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken')
    const response = await axios.get(
      `/counting/otherSalary/editedLog/${id}?page=${page}&limit=${limit}&search=${search}`,
    )
    return response
  }

  async get_deleted_bills({ start_date, end_date, page, limit, search }) {
    axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken')

    const response = await axios.get(
      `counting/otherSalary/deleted?search=${search}&start_date=${start_date}&end_date=${end_date}&page=${page}&limit=${limit}`,
    )

    return response
  }

  async get_edited_bills({ start_date, end_date, page, limit, search }) {
    axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken')

    const response = await axios.get(
      `counting/otherSalary/edited?search=${search}&start_date=${start_date}&end_date=${end_date}&page=${page}&limit=${limit}`,
    )

    return response
  }

  async getAll() {
    axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken')

    const response = await axios.get(`counting/otherSalary/all`)
    return response
  }

  async edit({
    id,
    customer_id,
    desc,
    service_type,
    date,
    price,
    quantity,
    payment_amount,
    discount_amount,
    edit_reason,
    received_by,
    tooth_place,
    tooth_number,
    xray_image,
  }) {
    axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken')
    const response = await axios.put('counting/otherSalary', {
      id,
      customer_id,
      desc,
      service_type,
      date,
      price,
      quantity,
      payment_amount,
      discount_amount,
      edit_reason,
      received_by,
      tooth_place,
      tooth_number,
      xray_image,
    })
    return response
  }

  // get statistics today
  async getStatisticsToday() {
    axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken')
    const response = await axios.get('counting/otherSalary/statistics/today')
    return response
  }

  async add({
    customer_id,
    desc,
    service_type,
    date,
    price,
    quantity,
    payment_amount,
    discount_amount,
    received_by,
    tooth_place,
    tooth_number,
    xray_image,
  }) {
    axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken')
    const response = await axios.post('counting/otherSalary', {
      customer_id,
      desc,
      service_type,
      date,
      price,
      quantity,
      payment_amount,
      discount_amount,
      received_by,
      tooth_place,
      tooth_number,
      xray_image,
    })
    return response
  }

  async remove({ id, delete_reason }) {
    axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken')
    const response = await axios.delete(`counting/otherSalary/id/${id}?delete_reason=${delete_reason}`)
    return response
  }

  async restore_deleted(id) {
    axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken')

    const response = await axios.get(`counting/otherSalary/restoreDeleted/id/${id}`)

    return response
  }

  async isPrinted(id) {
    axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken')
    const response = await axios.get(`counting/otherSalary/updateIsPrinted/id/${id}`)
    return response
  }
}

export default new salaryOtherDailyApi()
