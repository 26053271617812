<template>
  <div>
    <v-card class="primary-shade--light pa-3">
      <h1 class="text-center subtitle-4 black--text">الفوايتر اليومية</h1>
      <v-row class="mt-5">
        <v-col md="4" cols="12">
          <div class="d-md-flex">
            <v-btn tile color="primary" class="ml-2 mb-4 mb-md-0 mb-sm-0" :block="isScreenXs"
              @click="addDialog.open = true">
              اضافة فاتورة <v-icon right> fa-plus </v-icon>
            </v-btn>
            <v-btn tile color="success" :loading="xlsxData.downloadLoading" :block="isScreenXs"
              @click="getDataAxiosAll">
              تحميل اكسل <v-icon right> fa-download </v-icon>
            </v-btn>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col md="2" cols="12">
          <v-menu v-model="menuStartDate" :close-on-content-click="false" :nudge-right="40"
            transition="scale-transition" offset-y min-width="290px">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field v-model="tableModifier.start_date" dense label="من" outlined clearable readonly
                v-bind="attrs" v-on="on"></v-text-field>
            </template>
            <v-date-picker v-model="tableModifier.start_date" @input="menuStartDate = false"></v-date-picker>
          </v-menu>
        </v-col>
        <v-col md="2" cols="12">
          <v-menu v-model="menuEndDate" :close-on-content-click="false" :nudge-right="40" transition="scale-transition"
            offset-y min-width="290px">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field v-model="tableModifier.end_date" dense label="الى" outlined clearable readonly
                v-bind="attrs" v-on="on"></v-text-field>
            </template>
            <v-date-picker v-model="tableModifier.end_date" @input="menuEndDate = false"></v-date-picker>
          </v-menu>
        </v-col>
        <v-spacer></v-spacer>
        <v-col md="3" cols="12">
          <div class="d-flex flex-row">
            <v-text-field v-model="table.search" label="البحث" outlined dense @keyup.enter="search()"></v-text-field>
            <button class="search-btn" @click="search()">
              <v-icon style="font-size: 18px; color: white"> fa-search </v-icon>
            </button>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-data-table :headers="table.headers" loading-text="جاري التحميل ... الرجاء الانتظار" :items="table.Data"
            :options.sync="tableOptions" :server-items-length="table.totalData" :loading="table.loading"
            class="elevation-1" :footer-props="{
              itemsPerPageOptions: [10, 50, 100],
            }">
            <template slot="item._id" slot-scope="props">
              {{ table.totalData - props.index - (tableOptions.page - 1) * tableOptions.itemsPerPage }}
            </template>
            <template v-slot:item.price="{ item }">
              {{ numberWithComma(item.price) }}
            </template>
            <template v-slot:item.total_amount="{ item }">
              {{ numberWithComma(item.total_amount) }}
            </template>
            <template v-slot:item.payment_amount="{ item }">
              {{ numberWithComma(item.payment_amount) }}
            </template>
            <template v-slot:item.discount_amount="{ item }">
              {{ numberWithComma(item.discount_amount) }}
            </template>
            <template v-slot:item.remaining="{ item }">
              {{ numberWithComma(item.remaining) }}
            </template>
            <template v-slot:item.tooth_place="{ item }">
              <span v-if="item.tooth_place">{{ item.tooth_place }} - {{ item.tooth_number }}</span>
            </template>
            <template v-slot:item.xray_image="{ item }">
              <v-img :src="content_url + item.xray_image" width="50" height="50" v-if="item.xray_image"
                @click="showImage(item.xray_image)" class="teacher_image_table" />
            </template>
            <template v-slot:item.is_printed="{ item }">
              <v-chip color="success" v-if="item.is_printed" size="small">تمت الطباعة</v-chip>
              <v-chip color="#00695C" v-else>لم تتم الطباعة</v-chip>
            </template>
            <template v-slot:item.creator_name="{ item }">
              {{ item.creator_name }} ({{ item.creator_type_ar }})
            </template>
            <template v-slot:item.received_by_name="{ item }" v-if="center_id !== $store.state.s_and_s_clink_id">
              {{ item.received_by_name }}
            </template>
            <template v-slot:item.actions="{ item }">
              <v-tooltip bottom v-if="isAllowedToDelete(account_type)">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon color="#FF5252" class="ml-2" v-bind="attrs" size="20" v-on="on" @click="deleteItem(item)">
                    fa-trash
                  </v-icon>
                </template>
                <span>حذف</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon color="primary" class="ml-2" v-bind="attrs" size="20" v-on="on" @click="editItem(item)">
                    fa-edit
                  </v-icon>
                </template>
                <span>تعديل</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon color="#FDD835" class="ml-2" v-bind="attrs" size="20" v-on="on" @click="printPage(item)">
                    fa-print
                  </v-icon>
                </template>
                <span>طباعة</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-col>
        <v-col cols="12" md="6">
          <p class="text-h5">مجموع اسعار الفواتير لهذا اليوم</p>
          <table class="text-h5">
            <tr>
              <td>المجموع</td>
              <td>:</td>
              <td>{{ numberWithComma(today_statistics.total_amount) }}</td>
            </tr>
            <tr>
              <td>المدفوع</td>
              <td>:</td>
              <td>{{ numberWithComma(today_statistics.payment_amount) }}</td>
            </tr>
            <tr>
              <td>الخصم</td>
              <td>:</td>
              <td>{{ numberWithComma(today_statistics.discount_amount) }}</td>
            </tr>
            <tr>
              <td>المتبقي</td>
              <td>:</td>
              <td>{{ numberWithComma(today_statistics.remaining) }}</td>
            </tr>
          </table>
        </v-col>
        <v-col cols="12" md="6" v-if="account_type == 'manager'">
          <div style="text-align: end;">
            <h2>واردات هذه السنة : {{ numberWithComma(currentYearAndCurrentMonth.year) }}</h2>
            <h2>واردات هذا الشهر : {{ numberWithComma(currentYearAndCurrentMonth.month) }}</h2>
          </div>
        </v-col>
      </v-row>
    </v-card>
    <!-- add dialog -->
    <v-dialog v-model="addDialog.open" max-width="900px">
      <v-card>
        <v-card-title>
          <span class="headline">اضافة</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form v-model="addDialog.isFormValidAdd">
              <!-- this is the replacement !!! -->
              <v-row no-gutters>
                <!-- customer_id -->
                <v-col cols="12" md="6">
                  <v-autocomplete v-model="addData.customer_id" :rules="rules.required" :loading="customers.loading"
                    :items="customers.data" item-text="customer_name" item-value="_id" clearable outlined dense
                    label="اختيار زبون" @click:clear="addData.customer_id = null"></v-autocomplete>
                </v-col>
                <!-- section_id -->
                <v-col cols="12" md="6" v-if="thisId !== '660008e235f4f2b5748a67d3'">
                  <v-autocomplete v-model="addData.section_id" :rules="rules.required" :loading="sections.loading"
                    :items="sections.data" item-text="name" item-value="_id" clearable outlined dense
                    label="اختيار قسم منتج" @click:clear="addData.section_id = null"
                    @change="getAllServices()"></v-autocomplete>
                </v-col>
                <!-- service_selected -->
                <v-col cols="12" md="6">
                  <v-autocomplete v-model="addData.service_selected" :rules="rules.requiredObject"
                    :loading="services.loading" :items="services.data" item-text="name" clearable outlined dense
                    label="نوع الخدمة" return-object @click:clear="addData.service_type = null"
                    @change="service_selected_changed()"></v-autocomplete>
                </v-col>
                <!-- quantity -->
                <v-col cols="12" md="6" v-if="thisId !== '660008e235f4f2b5748a67d3'">
                  <v-currency-field v-model="addData.quantity" :error-messages="errors.rate" :rules="rules.required"
                    dense label="الكمية" outlined />
                </v-col>
                <!-- price -->
                <v-col cols="12" md="6">
                  <v-currency-field v-model="addData.price" :error-messages="errors.rate" :rules="rules.required" dense
                    label="المبلغ" outlined />
                </v-col>
                <!-- discount_amount -->
                <v-col cols="12" md="6" v-if="thisId !== '660008e235f4f2b5748a67d3'">
                  <v-currency-field v-model="addData.discount_amount" :error-messages="errors.rate"
                    :rules="rules.required" dense label="الخصم" outlined />
                </v-col>
                <!-- salaryPlusDiscountAmount -->
                <v-col cols="12" md="6">
                  <v-currency-field v-model="addData.salaryPlusDiscountAmount" :error-messages="errors.rate"
                    :rules="rules.required" dense label="المبلغ الكلي بعد الخصم" outlined disabled
                    class="font-weight-bold black--text" />
                </v-col>
                <!-- payment_amount -->
                <v-col cols="12" md="6">
                  <v-currency-field v-model="addData.payment_amount" :error-messages="errors.rate"
                    :rules="rules.payment_amount" dense label="المبلغ المدفوع" outlined />
                </v-col>
                <!-- remainingAmount -->
                <v-col cols="12" md="6">
                  <v-currency-field v-model="addData.remainingAmount" :error-messages="errors.rate"
                    :rules="rules.required" dense label="المبلغ المتبقي" outlined disabled />
                </v-col>
                <!-- date -->
                <v-col cols="12" md="6">
                  <v-menu v-model="menuDatee" :close-on-content-click="false" :nudge-right="40"
                    transition="scale-transition" offset-y min-width="290px">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field v-model="addData.date" dense :rules="rules.date" label="تاريخ انشاء الفاتورة"
                        outlined readonly v-bind="attrs" v-on="on">
                      </v-text-field>
                    </template>
                    <v-date-picker v-model="addData.date" @input="menuDate = false"> </v-date-picker>
                  </v-menu>
                </v-col>
                <!-- received_by -->
                <v-col cols="12" md="6" v-if="center_id !== $store.state.s_and_s_clink_id">
                  <v-combobox v-model="addData.received_by" :rules="rules.required" dense label="المستلم" outlined
                    clearable :items="employee.data" item-text="employee_name" item-value="_id"></v-combobox>
                </v-col>
                <!-- tooth_place -->
                <v-col cols="12" md="3" v-if="login_store.type === 'عيادة اسنان'">
                  <v-select v-model="addData.tooth_place" :items="tooth_place_items" dense label="موقع السن"
                    outlined></v-select>
                </v-col>
                <!-- tooth_number -->
                <v-col cols="12" md="3" v-if="login_store.type === 'عيادة اسنان'">
                  <v-text-field v-model="addData.tooth_number" dense label="رقم السن" outlined></v-text-field>
                </v-col>
                <!-- customer image -->
                <v-col cols="12" class="mb-2" v-if="login_store.type === 'عيادة اسنان'">
                  <div class="d-flex justify-space-around align-center">
                    <v-btn text color="primary" @click="$refs.customer_image_change.click()">
                      اضافة صورة الاشعة (اختياري)</v-btn>
                    <input v-show="false" id="img1" ref="customer_image_change" type="file"
                      @change="changeCustomerImage($event)" />
                    <div style="width: 80px; height: 80px; border: 1px solid red">
                      <img v-if="this.xray_image || this.addData.customer_image" width="80" height="80"
                        :src="showLogo()" />
                    </div>
                  </div>
                </v-col>
                <!-- desc -->
                <v-col cols="12">
                  <v-textarea outlined rows="2" row-height="10" v-model="addData.desc" label="الملاحظة"></v-textarea>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions class="ml-3">
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="addDialog.open = false"> الغاء </v-btn>
          <v-btn color="primary" :loading="addDialog.loading" :disabled="!addDialog.isFormValidAdd" @click="add">
            اضافة
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- add dialog -->
    <!-- edit dialog -->
    <v-dialog v-model="editDialog.open" max-width="900px">
      <v-card>
        <v-card-title>
          <span class="headline">تعديل</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form v-model="editDialog.isFormValid">
              <v-row no-gutters>
                <!-- customer_id -->
                <v-col cols="6">
                  <v-autocomplete v-model="editedItem.customer_id" :rules="rules.required" :loading="customers.loading"
                    :items="customers.data" item-text="customer_name" item-value="_id" clearable outlined dense
                    label="اختيار زبون" @click:clear="editedItem.customer_id = null"></v-autocomplete>
                </v-col>
                <!-- quantity -->
                <v-col cols="6">
                  <v-currency-field v-model="editedItem.quantity" :error-messages="errors.rate" :rules="rules.required"
                    dense label="الكمية" outlined />
                </v-col>
                <!-- price -->
                <v-col cols="6">
                  <v-currency-field v-model="editedItem.price" :error-messages="errors.rate" :rules="rules.required"
                    dense label="المبلغ" outlined />
                </v-col>
                <!-- discount_amount -->
                <v-col cols="6">
                  <v-currency-field v-model="editedItem.discount_amount" :error-messages="errors.rate"
                    :rules="rules.required" dense label="الخصم" outlined />
                </v-col>
                <!-- salaryPlusDiscountAmount -->
                <v-col cols="6">
                  <v-currency-field v-model="editedItem.salaryPlusDiscountAmount" :error-messages="errors.rate"
                    :rules="rules.required" dense label="المبلغ الكلي بعد الخصم" outlined disabled />
                </v-col>
                <!-- payment_amount -->
                <v-col cols="6">
                  <v-currency-field v-model="editedItem.payment_amount" :error-messages="errors.rate"
                    :rules="rules.required" dense label="المبلغ المدفوع" outlined />
                </v-col>
                <!-- remainingAmount -->
                <v-col cols="6">
                  <v-currency-field v-model="editedItem.remainingAmount" :error-messages="errors.rate"
                    :rules="rules.required" dense label="المبلغ المتبقي" outlined disabled />
                </v-col>
                <v-col cols="12" md="6">
                  <v-menu v-model="menuDate" :close-on-content-click="false" :nudge-right="40"
                    transition="scale-transition" offset-y min-width="290px">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field v-model="editedItem.date" dense :rules="rules.date" label="تاريخ انشاء الفاتورة"
                        outlined readonly v-bind="attrs" v-on="on">
                      </v-text-field>
                    </template>
                    <v-date-picker v-model="editedItem.date" @input="menuDate = false"> </v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" md="6">
                  <v-textarea outlined rows="2" row-height="10" :rules="rules.required" v-model="editedItem.edit_reason"
                    label="سبب التعديل"></v-textarea>
                </v-col>
                <!-- tooth_place -->
                <v-col cols="12" md="3" v-if="login_store.type === 'عيادة اسنان'">
                  <v-select v-model="editedItem.tooth_place" :items="tooth_place_items" dense label="موقع السن"
                    outlined></v-select>
                </v-col>
                <!-- tooth_number -->
                <v-col cols="12" md="3" v-if="login_store.type === 'عيادة اسنان'">
                  <v-text-field v-model="editedItem.tooth_number" dense label="رقم السن" outlined></v-text-field>
                </v-col>
                <!-- received_by -->
                <v-col cols="12" md="6" v-if="center_id !== $store.state.s_and_s_clink_id">
                  <v-autocomplete v-model="editedItem.received_by" :rules="rules.required" dense label="المستلم"
                    outlined clearable :items="employee.data" item-text="employee_name"
                    item-value="_id"></v-autocomplete>
                </v-col>
                <!-- customer image -->
                <v-col cols="12" class="mb-2" v-if="login_store.type === 'عيادة اسنان'">
                  <div class="d-flex justify-space-around align-center">
                    <v-btn text color="primary" @click="$refs.customer_image_change_edit.click()">
                      اضافة صورة (اختياري)</v-btn>
                    <input v-show="false" id="img1" ref="customer_image_change_edit" type="file"
                      @change="changeCustomerImageEdit($event)" />
                    <div style="width: 80px; height: 80px; border: 1px solid red">
                      <img v-if="this.xray_image_edit || this.editedItem.xray_image" width="80" height="80"
                        :src="showLogoEdit()" />
                    </div>
                  </div>
                </v-col>
                <v-col cols="12">
                  <v-textarea outlined rows="2" row-height="10" v-model="editedItem.desc" label="الملاحظة"></v-textarea>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions class="ml-3">
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="editDialog.open = false"> الغاء </v-btn>
          <v-btn color="primary" :loading="editDialog.loading" :disabled="!editDialog.isFormValid"
            @click="editItemConform">
            تعديل
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- edit dialog -->
    <!-- delete dialog -->
    <v-dialog v-model="dialogDelete.open" max-width="800px">
      <v-card>
        <v-card-title>
          <span class="headline">حذف</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form v-model="dialogDelete.isFormValid">
              <v-row no-gutters>
                <v-col cols="12">
                  <v-textarea :rules="rules.required" outlined rows="2" row-height="10"
                    v-model="dialogDelete.delete_reason" label="سبب الحذف"></v-textarea>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions class="ml-3">
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialogDelete.open = false"> الغاء </v-btn>
          <v-btn color="primary" :loading="dialogDelete.loading" :disabled="!dialogDelete.isFormValid"
            @click="deleteItemConfirm">
            حذف
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--- dialog for show info to user-->
    <v-dialog v-model="dialogData.open" max-width="500px">
      <v-toolbar :color="dialogData.color" dense></v-toolbar>
      <v-card>
        <v-card-title class="headline justify-center"> {{ dialogData.bodyText }} </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1 justify-start" text @click="dialogData.open = false"> تم </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- image Teacher dialog -->
    <v-dialog v-model="table.showImageDialog" max-width="600px">
      <v-card>
        <v-card-title>
          <span class="headline">عرض الصورة</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form>
              <v-row class="justify-center">
                <img :src="content_url + table.imageUrlForShow" alt width="300" height="200" />
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions class="ml-3">
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="table.showImageDialog = false"> الغاء </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- image Teacher dialog -->
  </div>
</template>

<script>
import customersApi from '@/api/customer'
import salary_other_daily from '@/api/salary_other_daily'
import sectionApi from '@/api/section_other'
import servicesApi from '@/api/service_other'
import { getCurrentDateInString } from '@/constant/date'
import numberWithComma from '@/constant/number'
import { mdiCash, mdiCashMultiple } from '@mdi/js'
import { isAllowedToDelete } from '@/constant/isAllowed'
import employeeApi from '@/api/employee'

export default {
  data() {
    return {
      rate: 0,
      thisId: null,
      today_statistics: {
        price: 0,
        payment_amount: 0,
        discount_amount: 0,
        remaining: 0,
      },

      account_type: null,

      errors: {},

      employee: {
        data: [],
        loading: false,
        selected: {},
        salary: null,
      },

      login_store: {
        type: null,
      },

      tooth_place_items: ['UL', 'UR', 'LL', 'LR'],

      account_type: null,

      menuStartDate: false,

      menuEndDate: false,

      icons: {
        mdiCashMultiple,
        mdiCash,
        mdiCashMultiple,
      },

      billsTypeSelectItems: [
        { text: 'الكل', value: null },
        { text: 'المدفوعة', value: true },
        { text: 'غير المدفوعة', value: false },
      ],

      isDeletedBillSelectItems: [
        { text: 'غير محذوفة', value: false },
        { text: 'محذوفة', value: true },
      ],

      tableModifier: {
        start_date: null,
        end_date: null,
      },

      rules: {
        required: [value => !!value || 'الحقل مطلوب'],
        payment_amount: [value => !!value || 'الحقل مطلوب'],
        // object required
        requiredObject: [value => !!value._id || 'الحقل مطلوب'],
      },

      menuNextPayment: false,

      addData: {
        customer_id: null,
        price: null,
        payment_amount: null,
        discount_amount: 0,
        remainingAmount: null,
        desc: null,
        service_type: null,
        service_selected: {},
        section_id: null,
        salaryPlusDiscountAmount: null,
        quantity: 1,
        received_by: null,
        tooth_place: null,
        tooth_number: null,
        xray_image: null,
        date: getCurrentDateInString(),
      },

      editedItem: {
        customer_id: null,
        price: null,
        payment_amount: null,
        discount_amount: null,
        remainingAmount: null,
        desc: null,
        service_type: null,
        service_selected: {},
        section_id: null,
        salaryPlusDiscountAmount: null,
        date: null,
        edit_reason: null,
        tooth_place: null,
        tooth_number: null,
        xray_image: null,
      },

      content_url: null,

      menuDate: false,
      menuDatee: false,
      currentYearAndCurrentMonth: [],

      dialogDelete: {
        open: false,
        loading: false,
        isFormValid: false,
        data: {},
        delete_reason: null,
      },

      dialogData: {
        open: false,
        color: 'primary',
        bodyText: 'test',
      },

      xlsxData: {
        list: [],
        listLoading: true,
        downloadLoading: false,
        filename: 'الفواتير',
        autoWidth: true,
        bookType: 'xlsx',
      },

      isScreenXs: false,

      deletedItem: {},

      tableOptions: {},

      addDialog: {
        open: false,
        isFormValidAdd: false,
        saveLoading: false,
        loading: false,
      },

      editDialog: {
        open: false,
        isFormValid: false,
        saveLoading: false,
        loading: false,
      },

      customers: {
        data: [],
        loading: false,
      },

      services: {
        data: [],
        loading: false,
      },

      sections: {
        data: [],
        loading: false,
      },

      nextPaymentDisable: false,

      otherData: {
        currencySymbol: ' IQD',
        payment_amount: 0,
        price: 0,
        discount_amount: 0,
      },

      center_id: null,

      xray_image: null,

      xray_image_edit: null,

      imgAndDivShowControll: {
        IsDiv1Showing: true,
        isImg1Showing: false,

        IsDiv1ShowingEdit: true,
        isImg1ShowingEdit: false,

        IsDiv2Showing: true,
        isImg2Showing: false,

        IsDiv3Showing: true,
        isImg3Showing: false,
      },

      table: {
        search: null,
        totalData: 0,
        Data: [],
        loading: true,
        showImageDailog: false,
        imageUrlForShow: null,

        showImageDialog: false,

        headers: [
          {
            text: '#',
            align: 'start',
            sortable: false,
            value: '_id',
          },
          {
            text: 'اسم الزبون',
            sortable: false,
            value: 'customer_name',
          },
          {
            text: 'نوع الخدمة',
            sortable: false,
            value: 'service_name',
          },
          {
            text: 'الهاتف',
            sortable: false,
            value: 'customer_mobile',
          },
          { text: 'مبلغ الخدمة', sortable: false, value: 'price' },
          { text: 'الكمية', sortable: false, value: 'quantity' },
          { text: 'المبلغ الكلي', sortable: false, value: 'total_amount' },
          { text: 'المبلغ المدفوع', sortable: false, value: 'payment_amount' },
          { text: 'الخصم', sortable: false, value: 'discount_amount' },
          { text: 'المتبقي', sortable: false, value: 'remaining' },
          { text: 'تاريخ الفاتورة', sortable: false, value: 'date' },
          { text: 'الطباعة', sortable: false, value: 'is_printed' },
          { text: 'رقم - موقع السن', sortable: false, value: 'tooth_place' },
          { text: 'صورة الاشعة', sortable: false, value: 'xray_image' },
          { text: 'الموظف', sortable: false, value: 'received_by_name' },
          { text: 'الملاحظات', sortable: false, value: 'desc' },
          { text: 'اضيف بواسطة', sortable: false, value: 'creator_name' },
          { text: 'العمليات', value: 'actions', sortable: false },
        ],
      },
    }
  },
  watch: {
    'tableModifier.start_date': {
      handler() {
        this.getDataAxios()
      },
      // deep: true,
    },

    'tableModifier.end_date': {
      handler() {
        this.getDataAxios()
      },
      // deep: true,
    },

    'addData.price': {
      handler() {
        this.salaryPlusDiscountAmountChange()
        this.paymentChange()
      },
      // deep: true,
    },

    'addData.quantity': {
      handler() {
        this.salaryPlusDiscountAmountChange()
        this.paymentChange()
      },
      // deep: true,
    },

    'addData.payment_amount': {
      handler() {
        this.salaryPlusDiscountAmountChange()
        this.paymentChange()
      },
      // deep: true,
    },

    'addData.discount_amount': {
      handler() {
        this.salaryPlusDiscountAmountChange()
        this.paymentChange()
      },
      // deep: true,
    },

    tableOptions: {
      handler() {
        this.getDataAxios()
      },
      deep: true,
    },
    '$route.query.search': {
      handler(search) {
        this.table.search = search
        this.getDataAxios(search)
      },
      deep: true,

      // immediate: true,
    },
    '$vuetify.breakpoint': {
      handler() {
        if (this.$vuetify.breakpoint.xs) {
          this.isScreenXs = true
        } else {
          this.isScreenXs = false
        }
      },
      deep: true,
    },
  },

  created() {
    this.getAllCustomers()
    this.getAllSections()
    this.getStatisticsTodayData()
    this.getCurrentYearAndCurrentMonth()
    this.getEmployee()

    const results = JSON.parse(localStorage.getItem('results'))
    this.login_store.type = results.type
    this.thisId = results.center_id
    if (this.thisId == '660008e235f4f2b5748a67d3') {
      this.addData.section_id = '660009bd35f4f2b5748a68cb'
      this.getAllServices()
    }
    if (results.type !== 'عيادة اسنان') {
      this.table.headers.splice(12, 2)
    }

    const account_type = this.$store.state.results.account_type

    this.account_type = account_type
  },

  methods: {
    async getDataAxios() {
      let { search } = this.table
      this.table.loading = true
      let { page, itemsPerPage } = this.tableOptions
      if (!search) {
        search = null
      }

      if (!page) {
        page = 1
      }
      if (!itemsPerPage) {
        itemsPerPage = 10
      }

      const response = await salary_other_daily.get({
        start_date: this.tableModifier.start_date,
        end_date: this.tableModifier.end_date,
        page,
        limit: itemsPerPage,
        search,
      })

      if (response.status === 401) {
        this.$store.dispatch('submitLogout')
      } else if (response.status === 500) {
        this.table.loading = false
        this.showDialogfunction('حصلت مشكلة يرجى المحاولة مجددا', '#FF5252')
      } else {
        this.table.loading = false
        this.table.Data = response.data.results.data
        this.table.totalData = response.data.results.count
        this.content_url = response.data.content_url
        this.getCurrentYearAndCurrentMonth()
      }
    },

    async getEmployee() {
      this.employee.loading = true

      const response = await employeeApi.getAll()

      if (response.status === 401) {
        this.employee.loading = false
        this.$store.dispatch('submitLogout')
      } else if (response.status === 500) {
        this.employee.loading = false
        this.showDialogfunction('حصلت مشكلة يرجى المحاولة مجددا', '#FF5252')
      } else {
        this.employee.loading = false
        this.employee.data = response.data.results
      }
    },

    async getCurrentYearAndCurrentMonth() {
      const response = await employeeApi.getCurrentYearAndCurrentMonth()

      if (response.status === 401) {
        this.$store.dispatch('submitLogout')
      } else if (response.status === 500) {
        this.showDialogfunction('حصلت مشكلة يرجى المحاولة مجددا', '#FF5252')
      } else {
        this.currentYearAndCurrentMonth = response.data.results
      }
    },

    async getDataAxiosAll() {
      this.xlsxData.downloadLoading = true

      const response = await salary_other_daily.getAll()

      if (response.status === 401) {
        this.$store.dispatch('submitLogout')
      } else if (response.status === 500) {
        this.xlsxData.downloadLoading = false
        this.showDialogfunction(response.data.message, '#FF5252')
      } else {
        this.xlsxData.downloadLoading = false
        this.xlsxData.list = response.data.results
        this.handleDownload()
      }
    },

    // async function get statistics today data
    async getStatisticsTodayData() {
      const response = await salary_other_daily.getStatisticsToday()

      if (response.status === 500) {
        // this.xlsxData.downloadLoading = false
        this.showDialogfunction(response.data.message, '#FF5252')
      } else {
        this.today_statistics = response.data.results
      }
    },

    salaryPlusDiscountAmountChange() {
      this.addData.salaryPlusDiscountAmount =
        (this.addData.price ? this.addData.price : 0) * (this.addData.quantity ? this.addData.quantity : 0) -
        (this.addData.discount_amount ? this.addData.discount_amount : 0)
      this.addData.salaryPlusDiscountAmount ? this.addData.salaryPlusDiscountAmount : 0
      if (this.addData.payment_amount > this.addData.salaryPlusDiscountAmount) {
        this.showDialogfunction('خطأ: المبلغ المدفوع أكبر من المبلغ الكلي بعد الخصم', '#FF5252')
        this.addData.payment_amount = null
      }
      if (this.addData.discount_amount > this.addData.salaryPlusDiscountAmount) {
        this.showDialogfunction('خطأ: مبلغ الخصم اكبر من المبلغ الكلي', '#FF5252')
        this.addData.discount_amount = null
      }
    },

    editItem(item) {
      this.editedItem = { ...item }
      this.editDialog.open = true
    },

    async editItemConform() {
      let xray_image = this.editedItem.xray_image ? await this.imageToBase64(this.editedItem.xray_image) : null

      this.editDialog.open = true
      this.editDialog.loading = true

      const response = await salary_other_daily.edit({
        id: this.editedItem._id,
        customer_id: this.editedItem.customer_id,
        date: this.editedItem.date,
        service_type: this.editedItem.service_type,
        desc: this.editedItem.desc,
        mobile: this.editedItem.mobile,
        price: this.editedItem.price,
        discount_amount: this.editedItem.discount_amount,
        payment_amount: this.editedItem.payment_amount,
        quantity: this.editedItem.quantity,
        edit_reason: this.editedItem.edit_reason,
        received_by: this.editedItem.received_by,
        tooth_place: this.editedItem.tooth_place,
        tooth_number: this.editedItem.tooth_number,
        xray_image,
      })

      if (response.status === 401) {
        this.$store.dispatch('submitLogout')
      } else if (response.status === 500) {
        this.editDialog.open = false
        this.editDialog.loading = false
        this.showDialogfunction(response.data.message, '#FF5252')
      } else {
        this.editDialog.open = false
        this.editDialog.loading = false
        this.getDataAxios()
        this.getStatisticsTodayData()
        this.showDialogfunction(response.data.message, 'primary')
      }
    },

    showImage(image) {
      this.table.showImageDialog = true
      this.table.imageUrlForShow = image
    },

    paymentChange() {
      this.addData.remainingAmount =
        (this.addData.salaryPlusDiscountAmount ? this.addData.salaryPlusDiscountAmount : 0) -
        (this.addData.payment_amount ? this.addData.payment_amount : 0)
      this.addData.remainingAmount ? this.addData.remainingAmount : 0
    },

    search() {
      this.$router.replace(
        {
          query: { search: this.table.search },
        },
        () => {},
      )
    },

    imageToBase64(file) {
      if (file) {
        if (file.name) {
          return new Promise((resolve, reject) => {
            const reader = new FileReader()
            reader.readAsDataURL(file)
            reader.onload = () => resolve(reader.result)
            reader.onerror = error => reject(error)
          })
        } else {
          return file
        }
      }
    },

    async add() {
      let xray_image = this.addData.xray_image ? await this.imageToBase64(this.addData.xray_image) : null

      this.addDialog.loading = true

      const response = await salary_other_daily.add({
        customer_id: this.addData.customer_id,
        date: this.addData.date,
        service_type: this.addData.service_type,
        desc: this.addData.desc,
        mobile: this.addData.mobile,
        price: this.addData.price,
        discount_amount: this.addData.discount_amount,
        payment_amount: this.addData.payment_amount,
        quantity: this.addData.quantity,
        received_by: this.addData.received_by,
        tooth_place: this.addData.tooth_place,
        tooth_number: this.addData.tooth_number,
        xray_image,
      })

      if (response.status === 401) {
        this.addDialog.loading = false
        this.addDialog.open = false
        this.$store.dispatch('submitLogout')
      } else if (response.status === 500) {
        this.addDialog.loading = false
        this.addDialog.open = false
        this.showDialogfunction(response.data.message, '#FF5252')
      } else {
        this.addDialog.loading = false
        this.addDialog.open = false

        this.addData.customer_id = null
        this.addData.date = getCurrentDateInString()
        this.addData.service_type = null
        this.addData.desc = null
        this.addData.mobile = null
        this.addData.price = null
        this.addData.discount_amount = 0
        this.addData.payment_amount = null
        this.addData.remainingAmount = null
        this.addData.section_id = null
        this.addData.service_selected = {}
        this.addData.salaryPlusDiscountAmount = null
        this.addData.quantity = 1
        this.addData.xray_image = null
        this.xray_image = null

        this.showDialogfunction(response.data.message, 'primary')
        this.getDataAxios()
        this.getStatisticsTodayData()
      }
    },

    deleteItem(item) {
      this.dialogDelete.data = { ...item }
      this.dialogDelete.open = true
    },

    async deleteItemConfirm() {
      this.dialogDelete.loading = true

      const response = await salary_other_daily.remove({
        id: this.dialogDelete.data._id,
        delete_reason: this.dialogDelete.delete_reason,
      })

      if (response.status === 401) {
        this.$store.dispatch('submitLogout')
      } else if (response.status === 500) {
        this.dialogDelete.loading = false
        this.showDialogfunction(response.data.message, '#FF5252')
      } else {
        this.dialogDelete.loading = false
        this.dialogDelete.open = false
        this.dialogDelete.delete_reason = null
        this.getDataAxios()
        this.getStatisticsTodayData()
        this.showDialogfunction(response.data.message, 'primary')
      }
    },

    async getAllCustomers() {
      this.customers.loading = true

      const response = await customersApi.getAll()

      if (response.status === 401) {
        this.customers.loading = false
        this.$store.dispatch('submitLogout')
      } else if (response.status === 500) {
        this.customers.loading = false
        this.showDialogfunction('حصلت مشكلة يرجى المحاولة مجددا', '#FF5252')
      } else {
        this.customers.loading = false
        this.customers.data = response.data.results
      }
    },

    async getAllServices() {
      this.services.loading = true

      const response = await servicesApi.getAllBySection(this.addData.section_id)

      if (response.status === 401) {
        this.services.loading = false
        this.$store.dispatch('submitLogout')
      } else if (response.status === 500) {
        this.services.loading = false
        this.showDialogfunction('حصلت مشكلة يرجى المحاولة مجددا', '#FF5252')
      } else {
        this.services.loading = false
        this.addData.service_selected = {}
        this.services.data = response.data.results
      }
    },

    async getAllSections() {
      this.sections.loading = true

      const response = await sectionApi.getAll()

      if (response.status === 401) {
        this.sections.loading = false
        this.$store.dispatch('submitLogout')
      } else if (response.status === 500) {
        this.sections.loading = false
        this.showDialogfunction('حصلت مشكلة يرجى المحاولة مجددا', '#FF5252')
      } else {
        this.sections.loading = false
        this.sections.data = response.data.results
      }
    },

    showDialogfunction(bodyText, color) {
      this.dialogData.open = true
      this.dialogData.bodyText = bodyText
      this.dialogData.color = color
    },

    isAllowedToDelete,

    handleDownload() {
      import('@/vendor/Export2Excel').then(excel => {
        const tHeader = [
          'اسم الزبون',
          'هاتف الزبون',
          'المبلغ الخدمة',
          'الكمية',
          'المبلغ الكلي',
          'المبلغ المدفوع',
          'الخصم',
          'المتبقي',
          'التاريخ',
        ]
        const filterVal = [
          'customer_name',
          'customer_mobile',
          'price',
          'quantity',
          'total_amount',
          'payment_amount',
          'discount_amount',
          'remaining',
          'date',
        ]

        // const { list } = this
        const data = this.formatJson(filterVal, this.xlsxData.list)
        excel.export_json_to_excel({
          header: tHeader,
          data,
          filename: this.xlsxData.filename,
          autoWidth: this.xlsxData.autoWidth,
          bookType: this.xlsxData.bookType,
        })
        this.xlsxData.downloadLoading = false
      })
    },

    formatJson(filterVal, jsonData) {
      return jsonData.map(v => filterVal.map(j => v[j]))
    },

    changeCustomerImage(event) {
      this.imgAndDivShowControll.IsDiv1Showing = false
      this.imgAndDivShowControll.isImg1Showing = true

      const file = event.target.files[0]
      this.xray_image = null
      if (typeof file !== 'undefined' && file !== null) {
        const fileExt = file.name.split('.').pop()
        if (
          fileExt.toLowerCase() === 'jpg' ||
          fileExt.toLowerCase() === 'jpeg' ||
          fileExt.toLowerCase() === 'png' ||
          fileExt.toLowerCase() === 'png' ||
          fileExt.toLowerCase() === 'gif'
        ) {
          this.xray_image = URL.createObjectURL(file)
          this.addData.xray_image = file
        } else {
          console.log('error_image_add')
        }
      } else {
        this.xray_image = null
      }
    },

    showLogo() {
      if (!this.xray_image) {
        return this.content_url + this.addData.xray_image
      } else {
        return this.xray_image
      }
    },

    changeCustomerImageEdit(event) {
      this.imgAndDivShowControll.IsDiv1ShowingEdit = false
      this.imgAndDivShowControll.isImg1ShowingEdit = true

      const file = event.target.files[0]
      this.xray_image_edit = null
      if (typeof file !== 'undefined' && file !== null) {
        const fileExt = file.name.split('.').pop()
        if (
          fileExt.toLowerCase() === 'jpg' ||
          fileExt.toLowerCase() === 'jpeg' ||
          fileExt.toLowerCase() === 'png' ||
          fileExt.toLowerCase() === 'png' ||
          fileExt.toLowerCase() === 'gif'
        ) {
          this.xray_image_edit = URL.createObjectURL(file)
          this.editedItem.xray_image = file
        } else {
          console.log('error_image_add')
        }
      } else {
        this.xray_image_edit = null
      }
    },

    showLogoEdit() {
      if (!this.xray_image_edit) {
        return this.content_url + this.editedItem.xray_image
      } else {
        return this.xray_image_edit
      }
    },

    printPage(item) {
      let routeData = this.$router.resolve({ name: 'anotherBillDetailsInvoice' })

      window.open(routeData.href, '_blank')
      localStorage.setItem('anotherBillDetailsInvoice', JSON.stringify(item))

      this.isPrintedDB(item._id)

      item.is_printed = true

      // router.push('studentBillInvoice')
    },

    service_selected_changed() {
      if (this.addData.service_selected) {
        this.addData.price = this.addData.service_selected.price
        this.addData.service_type = this.addData.service_selected._id
      } else {
        this.addData.price = null
        this.addData.service_type = null
        this.addData.service_selected = {}
      }
    },

    async isPrintedDB(id) {
      await salary_other_daily.isPrinted(id)
    },

    numberWithComma,
  },
}
</script>
